import { mergeProps, mergeRefs } from "@react-aria/utils";
import { Children, cloneElement, forwardRef, isValidElement } from "react";

export interface WrappableProps {
  parent?: React.ReactNode;
  children: React.ReactNode;
}

export const Wrappable = forwardRef<HTMLElement, WrappableProps>(
  function Wrappable({ parent, children, ...props }, ref) {
    if (!parent || !isValidElement(parent)) {
      return isValidElement(children)
        ? cloneElement(children, {
            ...mergeProps((children as any).props, props),
            ref: ref
              ? mergeRefs(ref, (children as any).ref)
              : (children as any).ref,
          })
        : null;
    }

    return cloneElement(
      parent,
      {
        ...mergeProps((parent as any).props, props),
        ref: ref ? mergeRefs(ref, (parent as any).ref) : (parent as any).ref,
      },
      Children.toArray(children),
    );
  },
);
