import { forwardRef } from "react";
import {
  TextArea as AriaTextArea,
  type TextAreaProps as AriaTextAreaProps,
} from "react-aria-components";
import { cnp, mapPropsVariants } from "~/ui/lib";
import { type TextFieldVariantProps, textFieldStyles } from "./styles";

export interface TextAreaProps
  extends AriaTextAreaProps,
    TextFieldVariantProps {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea({ className, ...rawProps }, ref) {
    const [props, variants] = mapPropsVariants(
      rawProps,
      textFieldStyles.variantKeys,
    );

    const { textarea: textareaStyles } = textFieldStyles(variants);
    return (
      <AriaTextArea
        {...props}
        ref={ref}
        className={cnp(className, textareaStyles())}
      />
    );
  },
);
