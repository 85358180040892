import { type VariantProps, tv } from "tailwind-variants";

export const textFieldStyles = tv({
  slots: {
    base: ["flex flex-col gap-1.5 font-sans text-base"],
    input: [""],
    textarea: ["min-h-[200px]"],
    helpBox: [
      "absolute -left-[38px] p-1 bg-white border border-gray-7 rounded-md text-blue-11",
    ],
  },
  compoundSlots: [
    {
      slots: ["input", "textarea"],
      className: [
        "block w-full py-3 px-3.5 bg-white shadow-[0_0_0_1px_var(--gray6),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010] transition-shadow outline-none",
        "hover:shadow-[0_0_0_1px_var(--gray8),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010]",
        "focus:shadow-[0_0_0_2px_var(--blue9),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010,inset_0_2px_3px_#00000010]",
      ],
    },
  ],
  // idk figure out if needed, right now used so `mapPropsVariants` doesnt complain
  variants: {
    error: {
      true: "",
    },
    accessoryMode: {
      before: {
        input: "rounded-r-lg",
        textarea: "rounded-r-lg",
      },
      after: {
        input: "rounded-l-lg",
        textarea: "rounded-l-lg",
      },
    },
  },
  compoundVariants: [
    {
      accessoryMode: [undefined] as any,
      className: {
        input: "rounded-lg",
        textarea: "rounded-lg",
      },
    },
  ],
});

export type TextFieldVariantProps = VariantProps<typeof textFieldStyles>;
