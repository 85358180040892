import { type VariantProps, tv } from "tailwind-variants";

export const menuStyles = tv({
  base: "p-1 w-52 bg-white rounded-lg shadow-lg overflow-auto border",

  variants: {},
});

export type MenuVariantProps = VariantProps<typeof menuStyles>;

export const menuItemStyles = tv({
  base: "group flex w-full items-center rounded-md px-3 py-2 box-border outline-none cursor-default text-gray-900 font-sans text-base focus:bg-violet-500 focus:text-white",

  variants: {},
});

export type MenuItemVariantProps = VariantProps<typeof menuItemStyles>;
