import { type VariantProps, tv } from "tailwind-variants";

export const buttonStyles = tv({
  slots: {
    base: [
      "relative shrink-0 flex items-center justify-between border bg-gradient-to-b appearance-none outline-none font-medium font-sans",
      "pressed:ring-4",
      "focus-visible:ring-2 focus-visible-unpressed:ring-offset-2",

      "disabled:cursor-not-allowed",
    ],
    label: "flex-1 flex items-center justify-center text-nowrap",
  },
  variants: {
    size: {
      xs: { base: "p-0.5 text-xs", label: "px-1.5 gap-0.5" },
      sm: { base: "p-1 text-sm", label: "px-2.5 gap-1.5" },
      md: { base: "p-1.5 text-base", label: "px-4 gap-1.5" },
      lg: { base: "p-2.5 text-lg", label: "px-8 gap-2" },
      xl: { base: "p-3.5 text-xl", label: "px-12 gap-2.5" },
    },
    color: {
      blue: {
        base: [
          "from-blue-9 to-blue-10 border-blue-10 text-white shadow-[inset_0_-4px_6px_-1px_var(--blue8),0_4px_6px_-1px_#00000010,0_2px_4px_-2px_#00000010]",
          "hover:from-blue-10 hover:to-blue-11",
          "pressed:ring-blueA-5",
          "focus-visible-unpressed:ring-blue-8",
        ],
      },
      gray: {
        base: [
          "from-gray-3 to-gray-4 border-gray-5 shadow-[inset_0_-4px_6px_-1px_var(--gray2),0_4px_6px_-1px_rgb(0_0_0_/_0.05),0_2px_4px_-2px_rgb(0_0_0_/_0.05)]",
          "hover:from-gray-4 hover:to-gray-5",
          "pressed:ring-grayA-3 pressed:border-gray-7 pressed:brightness-[0.95]",
          "focus-visible-unpressed:ring-gray-8",
        ],
      },
      red: {
        base: [
          "from-red-9 to-red-10 text-white border-red-10 shadow-[inset_0_-4px_6px_-1px_var(--red8),0_4px_6px_-1px_#00000010,0_2px_4px_-2px_#00000010]",
          "hover:from-red-10 hover:to-red-11",
          "pressed:ring-redA-5",
          "focus-visible-unpressed:ring-red-8",
        ],
      },
      black: {
        base: [
          "from-sand-11 to-sand-12 text-white border-sand-12 shadow-[inset_0_-4px_6px_-1px_var(--sand11),0_4px_6px_-1px_#00000010,0_2px_4px_-2px_#00000010]",
          "hover:to-black",
          "pressed:ring-sandA-7",
          "focus-visible-unpressed:ring-sand-10",
        ],
      },
      green: {
        base: [
          "from-jade-9 to-jade-10 border-jade-10 text-white shadow-[inset_0_-4px_6px_-1px_var(--jade8),0_4px_6px_-1px_#00000010,0_2px_4px_-2px_#00000010]",
          "hover:from-jade-10 hover:to-jade-11",
          "pressed:ring-jadeA-5",
          "focus-visible-unpressed:ring-jade-8",
        ],
      },
      outline: {
        base: [
          "from-blue-2 to-blue-3 border-blue-8 text-blue-10 shadow-[inset_0_-4px_6px_-1px_var(--blue2),0_4px_6px_-1px_rgb(0_0_0_/_0.05),0_2px_4px_-2px_rgb(0_0_0_/_0.05)]",
          "hover:from-blue-3 hover:to-blue-4",
          "pressed:ring-blueA-4 pressed:brightness-[0.95]",
          "focus-visible-unpressed:ring-blue-7",
        ],
      },
    },
    rounded: {
      none: "rounded-none",
      default: "",
      pill: "rounded-full",
    },
    fullWidth: {
      true: "w-full",
    },
    iconMode: {
      "start-edge": "",
      "end-edge": "",
      only: "aspect-square",
      before: "",
      after: "",
    },

    isPending: { true: "" },
  },
  defaultVariants: {
    size: "md",
    rounded: "default",
    iconMode: "before",
  },
  compoundVariants: [
    // colors
    {
      color: ["blue", "red", "black", "green"],
      className: "pressed:brightness-[0.9] pressed:saturate-[1.2]",
    },

    // rounded
    {
      size: "xs",
      rounded: "default",
      className: "rounded",
    },
    {
      size: "sm",
      rounded: "default",
      className: "rounded-md",
    },
    {
      size: "md",
      rounded: "default",
      className: "rounded-lg",
    },
    {
      size: "lg",
      rounded: "default",
      className: "rounded-xl",
    },
    {
      size: "xl",
      rounded: "default",
      className: "rounded-2xl",
    },

    // disabled/pending
    {
      isPending: [false, undefined] as any,
      className: "disabled:opacity-50",
    },
  ],
});

export type ButtonVariantProps = VariantProps<typeof buttonStyles>;

export const iconSizeMap: Record<
  Exclude<ButtonVariantProps["size"], undefined>,
  number
> = {
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 24,
};
