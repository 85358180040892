import { forwardRef } from "react";
import {
  Input as AriaInput,
  type InputProps as AriaInputProps,
} from "react-aria-components";
import { cnp, mapPropsVariants } from "~/ui/lib";
import { type TextFieldVariantProps, textFieldStyles } from "./styles";

export interface InputProps extends AriaInputProps, TextFieldVariantProps {
  // you must specify the `accessoryMode` for this to be rendered
  accessory?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, accessory, ...rawProps },
  ref,
) {
  const [props, variants] = mapPropsVariants(
    rawProps,
    textFieldStyles.variantKeys,
  );

  const { input: inputStyles } = textFieldStyles(variants);

  const { accessoryMode } = variants;

  return (
    <div className="flex-1 flex items-stretch">
      {accessoryMode === "before" && accessory}
      <AriaInput
        {...props}
        ref={ref}
        className={cnp(className, inputStyles())}
      />
      {accessoryMode === "after" && accessory}
    </div>
  );
});
