import { type Ref, forwardRef } from "react";
import {
  Menu as AriaMenu,
  type MenuProps as AriaMenuProps,
  MenuTrigger as AriaMenuTrigger,
  Popover as AriaPopover,
} from "react-aria-components";

import { cnp, mapPropsVariants } from "~/ui/lib";
import type { forwardRefType } from "../aria/utils";
import { type MenuVariantProps, menuStyles } from "./styles";

export interface MenuProps<T extends object>
  extends MenuVariantProps,
    Omit<AriaMenuProps<T>, "children"> {
  trigger: React.ReactNode;

  // we don't need renderProps into children so we can not use that functionality
  // for now
  // we may want to support func as children to support collections API
  children?: React.ReactNode;
}

export const Menu = (forwardRef as forwardRefType)(function Button<
  T extends object,
>(
  { className, trigger, children, ...rawProps }: MenuProps<T>,
  ref: Ref<HTMLDivElement>,
) {
  const [props, variants] = mapPropsVariants(rawProps, menuStyles.variantKeys);

  return (
    <AriaMenuTrigger>
      {trigger}
      <AriaPopover className={cnp(className, menuStyles(variants))}>
        <AriaMenu {...props} ref={ref} className="outline-none">
          {children}
        </AriaMenu>
      </AriaPopover>
    </AriaMenuTrigger>
  );
});
