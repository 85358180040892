import {
  Label as AriaLabel,
  TextField as AriaTextField,
  type TextFieldProps as AriaTextFieldProps,
} from "react-aria-components";

import { HelpCircleIcon } from "lucide-react";
import { cnp, mapPropsVariants } from "~/ui/lib";
import { type TextFieldVariantProps, textFieldStyles } from "./styles";

export interface TextFieldProps
  extends AriaTextFieldProps,
    Exclude<TextFieldVariantProps, "accessoryMode"> {
  title: React.ReactNode;

  description?: React.ReactNode;

  helpText?: React.ReactNode;

  headerAccessory?: React.ReactNode;

  // we don't need renderProps into children so we can not use that functionality
  // for now
  children?: React.ReactNode;
}

export function TextField({
  title,
  description,
  helpText,
  className,
  headerAccessory,
  children,
  ...rawProps
}: TextFieldProps) {
  const [props, variants] = mapPropsVariants(
    rawProps,
    textFieldStyles.variantKeys,
  );

  const { base: baseStyles, helpBox: helpBoxStyles } =
    textFieldStyles(variants);

  return (
    <AriaTextField {...props} className={cnp(className, baseStyles())}>
      <header className="flex justify-between items-end">
        <AriaLabel className="relative">
          {helpText && (
            <div className={helpBoxStyles()}>
              <HelpCircleIcon size={16} />
            </div>
          )}
          <span className="font-medium text-black">{title}</span>
          {description && <p>{description}</p>}
        </AriaLabel>
        {headerAccessory}
      </header>
      {children}
    </AriaTextField>
  );
}
