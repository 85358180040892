import { forwardRef } from "react";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from "react-aria-components";

import { cnp, mapPropsVariants } from "~/ui/lib";
import { ButtonContent } from "./ButtonContent";
import { type ButtonVariantProps, buttonStyles } from "./styles";

export interface ButtonProps
  extends ButtonVariantProps,
    Omit<AriaButtonProps, "children"> {
  icon?: React.ReactNode;

  // we don't need renderProps into children so we can not use that functionality
  // for now
  children?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button({ className, icon, isDisabled, children, ...rawProps }, ref) {
    const [props, variants] = mapPropsVariants(
      rawProps,
      buttonStyles.variantKeys,
    );

    const { isPending } = variants;

    const { base: baseStyles, label: labelStyles } = buttonStyles(variants);

    return (
      <AriaButton
        {...props}
        ref={ref}
        className={cnp(className, baseStyles())}
        isDisabled={isDisabled ?? isPending}
        aria-busy={isPending}
      >
        <ButtonContent icon={icon} variants={variants} styles={labelStyles}>
          {children}
        </ButtonContent>
      </AriaButton>
    );
  },
);
