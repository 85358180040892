import { forwardRef } from "react";

import { cnp, mapPropsVariants } from "~/ui/lib";
import { Wrappable } from "../Wrappable/Wrappable";
import { AriaLink, type AriaLinkProps } from "../aria/AriaLink";
import { ButtonContent } from "./ButtonContent";
import { type ButtonVariantProps, buttonStyles } from "./styles";

export interface ButtonLinkProps
  extends ButtonVariantProps,
    Omit<AriaLinkProps, "children" | "asChild"> {
  icon?: React.ReactNode;

  // do not pass isPending to a link
  isPending?: never;

  $wrap?: React.ReactNode;

  // we don't need renderProps into children so we can not use that functionality
  // for now
  children?: React.ReactNode;
}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLink(
    { className, icon, isDisabled, children, ...rawProps },
    ref,
  ) {
    const [props, variants] = mapPropsVariants(
      rawProps,
      buttonStyles.variantKeys,
    );

    const { isPending } = variants;

    const { base: baseStyles, label: labelStyles } = buttonStyles(variants);

    return (
      <AriaLink
        {...props}
        ref={ref}
        className={cnp(className, baseStyles())}
        isDisabled={isDisabled ?? isPending}
        aria-busy={isPending}
        asChild={!!props.$wrap}
      >
        <Wrappable parent={props.$wrap}>
          <ButtonContent icon={icon} variants={variants} styles={labelStyles}>
            {children}
          </ButtonContent>
        </Wrappable>
      </AriaLink>
    );
  },
);
