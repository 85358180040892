import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function cnp<RenderProps>(
  className: string | ((props: RenderProps) => string) | undefined,
  ...inputs: ClassValue[]
) {
  if (typeof className === "function") {
    return (props: RenderProps) => cn(...inputs, className(props));
  }

  return cn(...inputs, className);
}

export const mapPropsVariants = <
  T extends Record<string, any>,
  K extends keyof T,
>(
  props: T,
  variantKeys: K[],
): readonly [Omit<T, K>, Pick<T, K>] => {
  const picked = variantKeys.reduce((acc, key) => {
    // Only include the key in `picked` if it exists in `props`
    if (key in props) {
      // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
      return { ...acc, [key]: props[key] };
    } else {
      return acc;
    }
  }, {});

  const omitted = Object.keys(props)
    .filter((key) => !variantKeys.includes(key as K))
    // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
    .reduce((acc, key) => ({ ...acc, [key]: props[key as keyof T] }), {});

  return [omitted, picked] as [Omit<T, K>, Pick<T, K>];
};
