import { Loader2Icon } from "lucide-react";
import { Fragment, cloneElement, isValidElement } from "react";

import { cn } from "~/ui/lib";

import { mergeProps } from "react-aria";
import { type ButtonVariantProps, iconSizeMap } from "./styles";

export interface ButtonContentProps {
  icon?: React.ReactNode;

  variants: ButtonVariantProps;

  // not quite my favorite thing but it's okay for now
  styles: () => string;

  children?: React.ReactNode;
}

export const ButtonContent = ({
  icon,
  variants,
  styles,
  children,
}: ButtonContentProps) => {
  const { iconMode, isPending, size } = variants;

  const iconSize = iconSizeMap[size ?? "md"];

  const iconProps = {
    icon,
    iconMode: iconMode ?? "before",
    isPending,
    iconSize,
  } as const;

  return (
    <Fragment>
      <EdgeContent position="start-edge" {...iconProps} />

      {iconMode === "only" ? (
        <IconOrSpinner position="only" {...iconProps} />
      ) : (
        <span className={cn(isPending && !icon && "invisible", styles())}>
          <IconOrSpinner position="before" {...iconProps} />
          {children}
          <IconOrSpinner position="after" {...iconProps} />
        </span>
      )}

      <EdgeContent position="end-edge" {...iconProps} />

      {/* alternatively, if there is no icon but we are pending, show overlay spinner */}
      {isPending && !icon && (
        <span className="flex items-center justify-center absolute inset-0">
          <Loader2Icon className="animate-spin" size={iconSize} />
        </span>
      )}
    </Fragment>
  );
};

interface IconOrSpinnerProps {
  isPending?: boolean;
  icon?: React.ReactNode;
  position: ButtonVariantProps["iconMode"];
  iconMode: ButtonVariantProps["iconMode"];
  iconSize: number;
}

function IconOrSpinner({
  position,
  iconMode,
  isPending,
  icon: rawIcon,
  iconSize,
}: IconOrSpinnerProps) {
  // if there is no icon, we don't want to render anything
  // or if this does not match the iconMode, we don't want to render anything
  if (!rawIcon || position !== iconMode) return null;

  const getIcon = (icon?: React.ReactNode) => {
    return isValidElement(icon)
      ? cloneElement(
          icon,
          mergeProps(icon.props, {
            size: iconSize,
            "aria-hidden": true,
            focusable: false,
            tabIndex: -1,
            className: "shrink-0",
          }),
        )
      : null;
  };

  if (isPending) {
    return getIcon(<Loader2Icon className="animate-spin" size={iconSize} />);
  }

  return getIcon(rawIcon);
}

function EdgeContent(props: IconOrSpinnerProps) {
  if (props.iconMode !== "start-edge" && props.iconMode !== "end-edge") {
    return null;
  }

  // this always renders this span to "true center" the content
  // but we need it to be the same size as the actually shown icon
  return (
    <span
      className={cn(props.iconMode !== props.position && "invisible")}
      aria-hidden={props.iconMode !== props.position}
    >
      <IconOrSpinner {...props} position={props.iconMode} />
    </span>
  );
}
