import { type Ref, forwardRef } from "react";
import {
  MenuItem as AriaMenuItem,
  type MenuItemProps as AriaMenuItemProps,
} from "react-aria-components";

import { cnp, mapPropsVariants } from "~/ui/lib";
import type { forwardRefType } from "../aria/utils";
import { type MenuItemVariantProps, menuItemStyles } from "./styles";

export interface MenuItemProps<T extends object>
  extends MenuItemVariantProps,
    Omit<AriaMenuItemProps<T>, "children"> {
  // we don't need renderProps into children so we can not use that functionality
  // for now
  // we may want to support func as children to support collections API
  children?: React.ReactNode;
}

export const MenuItem = (forwardRef as forwardRefType)(function Button<
  T extends object,
>(
  { className, children, ...rawProps }: MenuItemProps<T>,
  ref: Ref<HTMLDivElement>,
) {
  const [props, variants] = mapPropsVariants(
    rawProps,
    menuItemStyles.variantKeys,
  );

  return (
    <AriaMenuItem
      className={cnp(className, menuItemStyles(variants))}
      ref={ref}
      {...props}
    >
      {children}
    </AriaMenuItem>
  );
});
