"use client";

import { ArrowRightIcon, CheckIcon } from "lucide-react";
import Image from "next/image";
import { useEffect } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { cn } from "~/ui/lib";
import { Button } from "~/ui/primitives";

interface Props {
  type: "directory" | "site";
  id: string;
  title: string;
  logo?: string | null;
  action: (
    prev: unknown,
    formData: FormData,
  ) => Promise<{ ok: boolean | null; message?: string }>;
}

const initialState = { ok: null };

export default function LoginForm({ type, id, title, logo, action }: Props) {
  const [state, formAction] = useFormState(action, initialState);

  useEffect(() => {
    if (state.ok) {
      window.location.reload();
    }
  }, [state.ok]);

  return (
    <form action={formAction}>
      <fieldset
        disabled={!!state.ok}
        className="relative flex flex-col justify-center items-center gap-6 p-6"
      >
        <input hidden type="hidden" name="id" value={id} />
        <input hidden type="hidden" name="type" value={type} />
        {logo && (
          <Image src={logo} alt={title} width={100} height={100} priority />
        )}
        <div className="flex flex-col gap-2 text-center">
          <h2 className="font-bold text-xl">{title} is private</h2>
          <p>Enter the password to gain access.</p>
        </div>
        <div className="flex items-center">
          <input
            type="password"
            name="password"
            placeholder="Password"
            className={cn(
              "py-2 px-3.5 min-w-80 outline-none shadow-[0_0_0_1px_var(--gray6),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010] rounded-l-xl",
              "hover:shadow-[0_0_0_1px_var(--gray8),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010]",
              "focus:shadow-[0_0_0_1px_var(--blue9),0_4px_6px_-2px_#00000010,0_2px_4px_-2px_#00000010,inset_0_2px_3px_#00000010]",
            )}
          />
          <SubmitButton completed={!!state.ok} />
        </div>
        {state.message && (
          <p className="absolute -bottom-4 text-red-500">{state.message}</p>
        )}
      </fieldset>
    </form>
  );
}

function SubmitButton({ completed }: { completed: boolean }) {
  const { pending } = useFormStatus();

  return (
    <Button
      color={completed ? "green" : "blue"}
      type="submit"
      isPending={pending}
      icon={completed ? <CheckIcon /> : <ArrowRightIcon />}
      iconMode="only"
      aria-label="Login"
      className="rounded-l-none"
      size="lg"
    />
  );
}
